import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ServiceWorkerProvider } from 'toolbox';
import './tailwind.css';

import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV.startsWith('prod')) {
  Sentry.init({
    release: 'gouldson@' + process.env.npm_package_version,
    //dsn: 'https://072d7def23a540bda76ba12da1744a30@o458228.ingest.sentry.io/5556537',
    dsn: 'https://a6d2729943f777b78d935b7f442e919f@o4508132253827072.ingest.de.sentry.io/4508143278555216',
  });
}

// test localStorage (workaround for potential Safari bug where 'quota exceeded')
try {
  localStorage.setItem('test', 'test');
  localStorage.removeItem('test');
} catch (e) {
  // log the error to Sentry
  Sentry.captureException(
    `Writing to localStorage failed: ${
      e instanceof Error ? e.message : JSON.stringify(e)
    }`,
  );

  // override Storage prototype so that localStorage works in-memory using tmp_storage var in lieu of writing to disk
  var tmp_storage: { [key: string]: any } = {};
  var p = '__unique__'; // prefix all keys to avoid matching built-ins
  Storage.prototype.setItem = function (k, v) {
    tmp_storage[p + k] = v;
  };
  Storage.prototype.getItem = function (k) {
    return tmp_storage[p + k] === undefined ? null : tmp_storage[p + k];
  };
  Storage.prototype.removeItem = function (k) {
    delete tmp_storage[p + k];
  };
  Storage.prototype.clear = function () {
    tmp_storage = {};
  };
}

ReactDOM.render(
  <Suspense fallback={null}>
    <ServiceWorkerProvider>
      <App />
    </ServiceWorkerProvider>
  </Suspense>,
  document.getElementById('root'),
);
